import portalControllers from './_controllers';

portalControllers
.controller('CalendarCtrl', ['$scope', '$http', 'API', 'USER_TYPE', function($scope, $http, API, USER_TYPE) {
	$scope.categoryMap = [
		{'id':1, 'name':'General','icon': 'fa-comment', 'color':'#008e79'},
		{'id':2, 'name':'Branding', 'icon': 'fa-image', 'color':'#830348'},
		{'id':3, 'name':'Marketing', 'icon': 'fa-bullhorn', 'color':'#5e0398'},
		{'id':4, 'name':'Event', 'icon': 'fa-calendar-alt', 'color':'#2390b0'},
		{'id':5, 'name':'Gateway', 'icon': 'fa-exclamation-circle', 'color':'#266588'},
		{'id':0, 'name':'RSVP', 'icon': 'fa-clock', 'color': '#e06026'}
	];

	$scope.selectedCategory = null;

	$scope.selectCategory = function(category) {
		$scope.selectedCategory = category;
	};

	$scope.filterCalendar = function() {
		return function(element){
			if ($scope.selectedCategory !== null) {
				return element.category == $scope.selectedCategory.id;
			}
			return true;
		};
	};

	$scope.filterCategories = function() {
		return function(element){
			if ((USER_TYPE != 'admin' && element.id == 5) || element.id === 0) {
				return false;
			}
			return true;
		};
	};

	$scope.calendarEvents = null;
	$scope.selected_calendarEvent = {
		client_account_id: null
	};
	$scope.user_type = USER_TYPE;
	$scope.errors = [];

	$scope.currentView = 'view';

	$scope.changeView = function(newView) {
	  $scope.currentView = newView;
    };

	$scope.getCategoryObject = function(id) {
		return $scope.categoryMap.find(function (element) {
			return element.id == id;
		});
	};

	//When the modal is hidden, revert back to default view
    $('#calendarEvent-modal').on('hide.bs.modal', function (e) {
        $scope.currentView = 'view';
        $scope.errors = [];
    });

	$scope.$watch(
	    function () {
	        return $('#main-col').height();
        },
        function() {

            var styleObject = {
                'max-height' : '100%'
            };

            var mainHeight = $('#main-col').height();

            if (mainHeight > 0 && USER_TYPE != 'vendor') {
                styleObject.overflow = 'auto';
                mainHeight -= 89;
                styleObject['max-height'] = mainHeight + 'px';
            } else if (USER_TYPE == 'vendor') {
                styleObject.overflow = 'auto';
                mainHeight = 370;
                styleObject['max-height'] = mainHeight + 'px';
			}

            $scope.panel_style = styleObject;
        }
    );

	//$scope.determineListStyle =
	//$scope.panel_style = $scope.determineListStyle();
	function loadCalendar(){
		$scope.is_loading = true;
		$http.get('/api/v2/calendar').then(
            function success(response) {
                $scope.is_loading = false;
                $scope.calendarEvents = response.data;
                appendCategoryObjects();
            }, function fail(response) {
                console.error(response);
            }
        );
    }

    function appendCategoryObjects() {
        for(var x in $scope.calendarEvents){
            for(var y in $scope.calendarEvents[x]) {
                if ($scope.calendarEvents[x][y].date == $scope.calendarEvents[x][y].deadline_at) {
                    $scope.calendarEvents[x][y].categoryObject = angular.copy($scope.getCategoryObject(0));
                    $scope.calendarEvents[x][y].categoryObject.name = $scope.getCategoryObject($scope.calendarEvents[x][y].category).name;
                } else {
                    $scope.calendarEvents[x][y].categoryObject = $scope.getCategoryObject($scope.calendarEvents[x][y].category);
                }
            }
		}
	}
    if (USER_TYPE == 'admin') {
        API.call('clients?limit=-1&return-func=toBasic&sort[name]=asc').success(function(response) {
            $scope.clients = response.data;
        });
    }

    loadCalendar();
	$scope.selectCalendarEvent = function(calendarEvent) {
	    if (!calendarEvent.id) {
	        $scope.changeView('edit');
        }
        $scope.selected_calendarEvent = calendarEvent;
        $('#calendarEvent-modal').modal('toggle');
	};

    $scope.postCalendarApi = function () {
        $scope.is_loading = true;
        var url = '/api/v2/calendar/' + ($scope.selected_calendarEvent.id || '');
        $http.post(url, $scope.selected_calendarEvent).then(
            function success(response) {
                loadCalendar();
                $scope.selected_calendarEvent = response.data;
                $scope.changeView('view');
                $scope.errors = [];
            }, function fail(response) {
                $scope.is_loading = false;
                console.log(response);
                $scope.errors = response.data;
            }
        );
	};

    $scope.deleteCalendarEvent = function() {
        $scope.is_loading = true;
        var url = '/api/v2/calendar/' + ($scope.selected_calendarEvent.id || '');
        $http.delete(url).then(
            function success() {
                loadCalendar();
                $scope.selected_calendarEvent = {};
                $('#calendarEvent-modal').modal('toggle');
                $scope.errors = [];
            }, function fail(response) {
                $scope.is_loading = false;
                console.log(response);
                $scope.errors = response.data;
            }
        );
    };

    $scope.button_text = 'Upload';

    var options = {
        data: {
            type: 'private',
            scope: 'announcement',
            action: 'attachment'
        },
        previousSize: 0,
        handleProgress: function(e) {
            if (!e) return;

            var progress = this.totalProgress + e.loaded;

            var size = parseInt(progress / this.totalSize * 100, 10);

            $scope.button_text = size + '% uploaded...';

            if (size >= 100) $scope.button_text = 'Finalizing...';

            if (!$scope.$$phase) $scope.$apply();
        },
        handleComplete: function(e) {
            var status = e.target.status;

            switch (status) {
                case 200:
                    var json = $.parseJSON(e.target.response);

                    if (!json.success) {
                        growlError(json.message);
                    } else {
                        $scope.fileError = false;
                        $scope.selected_calendarEvent.file = json.data;
                        $scope.selected_calendarEvent.attachment_file_id = json.data.id;
                    }

                    break;
                default:
                    growlError('Upload failed!');
                    break;
            }

            $scope.button_text = 'Upload';

            if (!$scope.$$phase) $scope.$apply();
        }
    };

    $scope.uploadFile = function() {
        $.each(arguments, function(i, file) {
            new FileUploader(file, options);
        });
    };
}])
.controller('AdminDashboardCtrl', ['$scope', '$http', 'API', '$controller', function($scope, $http, API, $controller) {
    angular.extend(this, $controller('CalendarCtrl', {$scope: $scope}));
}])
.controller('VendorAlertsModalCtrl', ['$scope', '$rootScope', '$routeParams', '$http', 'API', function($scope, $rootScope, $routeParams, $http, API) {

	$scope.customAlert = {};
	$scope.createCustomAlert = function() {

		if (!$scope.customAlertFormCreate.$valid) {
			growlError("Please enter all fields");
			return;
		}

		$http.post('/api/v2/vendors/' + $routeParams.accountID + '/flags', $scope.customAlert).then(
			function success(response) {

				$('#manual-alert-modal').modal('hide');
				$rootScope.$broadcast('VendorAlertsModal:customAlertUpdated', 'create');

				$scope.customAlert = {};
			}, function fail(response) {
				console.error(response);
			}
		);
	};


	$scope.editCustomAlert = function() {
		if (!$scope.customAlertFormEdit.$valid) {
			growlError("Please enter all fields");
			return;
		}
		$http.post('/api/v2/flags/' + $scope.editAlert.id, $scope.editAlert).then(
			function success(response) {
				$('#edit-alert-modal').modal('hide');
				$rootScope.$broadcast('VendorAlertsModal:customAlertUpdated', 'edit');

				$scope.editAlert = {};
			}, function fail(response) {
				console.error(response);
			}
		);
	};
	$rootScope.$on('VendorAlerts:markEditAlert', function(event, data) {
		$scope.editAlert = data;
	});

	$scope.deleteCustomAlert = function() {
		$http.delete('/api/v2/flags/' + $scope.deletedAlert.object_id).then(
			function success(response) {
				$('#confirm-delete-alert').modal('hide');
				$rootScope.$broadcast('VendorAlertsModal:customAlertUpdated', 'delete');

				$scope.deleteAlert = {};


			}, function fail(response) {
				console.error(response);
			}
		);
	};
	$rootScope.$on('VendorAlerts:markDeleteAlert', function(event, data) {
		$scope.deletedAlert = data;
	});

}])
.controller('VendorAlertsCtrl', ['$scope', '$rootScope', '$http', 'API', 'AccountAlerts', '$routeParams', function($scope, $rootScope, $http, API, AccountAlerts, $routeParams) {

	$rootScope.$on('VendorAlertsModal:customAlertUpdated', function (event, data) {
		$scope.alerts = false;
		pullAlerts();
	});

	var path_parts = getPathParts();
	var isAdminPage = (path_parts[0] === 'vendors');
	$scope.retrieveAlertsFunction = isAdminPage ? AccountAlerts.getForVendor : AccountAlerts.getForCurrent;
	$scope.showRefresh = isAdminPage;

	var pullAlerts = function() {
		//for a vendor, this needs to just call /flags rather than /vendors/id/flags
		$scope.retrieveAlertsFunction({accountID: $routeParams.accountID}, function(response) {
			var alertSections = response.data;

			alertSections = alertSections.sort(function sortByPriority(a, b) {
				return a.alert_status - b.alert_status;
			});


			$scope.alerts = response;
			$scope.alerts.data = alertSections;
      $scope.alerts.lastUpdated = moment.unix(response.cached_at);
		});
	};
  $scope.alertLink = function(link) {
      if (isAdminPage) {
        return `/vendors/${$routeParams.accountID}${link}`;
      }
      return link;


  };

	pullAlerts();


	$scope.refreshAlerts = function() {
    gtag('event', 'alert_refresh');
		$scope.alerts = false;
		$http.get('/api/v2/vendors/' + $routeParams.accountID + '/flags/refresh').then(
			function success(response) {
				var alertSections = response.data.data;
				alertSections = alertSections.sort(function sortByPriority(a, b) {
					return a.alert_status - b.alert_status;
				});

				$scope.alerts = response.data;
				$scope.alerts.data = alertSections;
        $scope.alerts.lastUpdated = moment.unix(response.cached_at);
			}
		);
	};

	$scope.markEditAlert = function(alert, alertSection) {
		$rootScope.$broadcast('VendorAlerts:markEditAlert', {
			'id': alert.object_id,
			'type': alertSection.id,
			'title': alert.display_status.title,
			'message': alert.display_status.message,
			'severity': alert.alert_status,
			'hides_all': alert.hides_all
		});
	};

	$scope.markDeleteAlert = function(alert) {
		$rootScope.$broadcast('VendorAlerts:markDeleteAlert', alert);
	};

}])
.controller('AdminVendorDashboardCtrl', ['$scope', '$routeParams', 'AccountWithTransformer', 'AccountProfileUpdate', 'AccountProfileVersions', function($scope, $routeParams, AccountWithTransformer, AccountProfileUpdate, AccountProfileVersions) {

	AccountProfileVersions.get({ id: $routeParams.accountID }, function(response) {
		$scope.status_versions = response.statuses;
  });

  AccountWithTransformer.get({ accountID: $routeParams.accountID }, (response) => {
    $scope.account = response.data;
  });

  AccountProfileUpdate.get({ accountID: $routeParams.accountID, }, (response) => {
    $scope.accountUpdate = response.data;
  });

  $scope.thirtyDayNotice = (d) => {
    const date = moment(d);
    const start = date.format('dddd, MMMM Do, YYYY');
    const end = date.add(30, 'days');

    return `Started on ${start} and ending on ${end.format('dddd, MMMM Do, YYYY')} (${end.fromNow()})`;
  };

}])
.controller('AdminClientDashboardCtrl', ['$scope', '$routeParams', 'AccountWithTransformer', 'AccountProfileUpdate', function($scope, $routeParams, AccountWithTransformer, AccountProfileUpdate) {

  AccountWithTransformer.get({ accountID: $routeParams.accountID }, (response) => {
    $scope.account = response.data;
  });

  AccountProfileUpdate.get({ accountID: $routeParams.accountID, }, (response) => {
    $scope.accountUpdate = response.data;
  });

}])
.controller('ClientDashboardCtrl', ['$scope', '$http', 'API', '$controller', function($scope, $http, API, $controller) {
    angular.extend(this, $controller('CalendarCtrl', {$scope: $scope}));
	API.getAccount().success(function(response) {
		$scope.account = response.data;
	});
}])
.controller('VendorDashboardCtrl', ['$scope', '$routeParams', 'API', '$controller', function($scope, $routeParams, API, $controller) {
    angular.extend(this, $controller('CalendarCtrl', {$scope: $scope}));
    $scope.OPS_LOADED_COUNT = 0;

	$scope.ospLoaded = function() {
		$scope.OPS_LOADED_COUNT += 1;

		// if twice that means they submitted so do something special
		if ($scope.OPS_LOADED_COUNT > 1) {
			$('.modal').modal('hide');
		}
	};
	$scope.updatePaymentMethod = function() {
		API.paymentMethod($routeParams.accountID).success(function(response) {
			$scope.payment_method = response.data;
		});
	};

	API.getAccount().success(function(response) {
		$scope.account = response.data;
	});

	API.call('account/payment-method').success(function(response) {
		$scope.payment_method = response.data;
	});

}]);
